<template>
  <div>
    <div class="banner">
        <img src="https://resources.holowits.com/terms/banner3.png" alt=""/>
    </div>
    <div class="list">
        <div class="item">
            <div class="t-label">1 Introduction</div>
            <div class="t-small-label">1.1 Purpose and Scope:</div>
            <div class="t-content">
                <p>This policy defines the return and repair policy applicable to products purchased by Distributors from HOLOWTIS. This RMA Policy applies only to HOLOWTIS products and any exceptions to this policy requested by the customer must be approved in writing by HOLOWTIS.</p>
            </div>
            <div class="t-small-label">1.2 Amending the Policy: </div>
            <div class="t-content">
                <p>HOLOWTIS may choose to modify this Policy in part or in whole at any time without notice.</p>
            </div>
            <div class="t-small-label">1.3 HOLOWTIS 's liability:  </div>
            <div class="t-content">
                <p>HOLOWTIS 's liability under this policy is limited to the modification or replacement of any defective product returned by an authorized overseas distributor. HOLOWTIS provides RMA service for defective products to its authorized distributors during the warranty period. If you are an end user, please contact the seller and they will assist you with the RMA service.</p>
            </div>
            <div class="t-small-label">1.4 Warranty Period Calculation:   </div>
            <div class="t-content">
                <p>The warranty normally starts on the 90th day after the date of the product shipment from HOLOWITS, or the date on which HOLOWITS receives a service request for this product, whichever is earlier. Details of Standard Warranty Period: For details on the product warranty provided by HOLOWITS for the respective products, please refer to the following link: <a style="color: #c7000b; border-bottom: 1px solid #c7000b;" href="https://holowits.com/warrantypolicy">https://holowits.com/warrantypolicy</a></p>
            </div>
        </div>
        <div class="item">
            <div class="t-label">2 General Return Requirement: </div>
            <div class="t-small-label">2.1 RMA Number:</div>
            <div class="t-content">
                <p>Distributors must obtain HOLOWITS approval and provide details as required before returning any product (Appendix 1). The application method is to send an email to <a style="color: #c7000b; border-bottom: 1px solid #c7000b;" href="mailto:support@holowits.com">support@holowits.com</a>. We will review the application and inform you of the processing status as soon as possible.</p>
            </div>
            <div class="t-small-label">2.1.1 RMA Discrepancies: </div>
            <div class="t-content">
                <p>If HOLOWITS determines upon receipt that the product does not meet the return/replacement requirements, or the product information or quantity is inconsistent with the description in the application, HOLOWITS reserves the right to refuse contact with the Distributor and to return the products at all costs incurred by the Distributor. </p>
            </div>
            <div class="t-small-label">2.1.2 Expiration: </div>
            <div class="t-content">
                <p>Distributors are required to return the product within 30 calendar days after HOLOWITS agrees to the RMA, otherwise a new RMA is required. Some defective parts can be scrapped locally after an RMA is issued.</p>
            </div>
            <div class="t-small-label">2.2 Data safety: </div>
            <div class="t-content">
                <p>It is the Distributor's responsibility to back up the programs and all data contained in the product before returning it. HOLOWITS is not responsible for any damage or loss of the above programs or data that may result from the maintenance service. To ensure your account and password security, it is recommended that you restore the product to factory settings before returning it so that HOLOWITS can log in to the product system for troubleshooting without using the account password. If you do not restore factory settings, HOLOWITS will contact you when you need to use your product account and password. You should reset your password after the product is repaired.</p>
            </div>
            <div class="t-small-label">2.3 Packaging Requirement: </div>
            <div class="t-content">
                <p>All returned products must be properly packaged to prevent shipping damage and provide appropriate electrostatic discharge (ESD) protection. Each package must be marked with a standard RMA number. (Writing in large black or blue font on the outside of each package) and seal properly. A copy of the RMA form and packing list contents should be attached to the package for our verification.</p>
            </div>
            <div class="t-small-label">2.4 Warranty Period Description</div>
            <div class="t-content">
                <p>Replacement parts may be new or equivalent to new in performance. If the product or part is no longer in production or is out of stock, HOLOWITS will provide another type of product or part with performance equal to or better than the original. HOLOWITS warrants any replaced product or part for ninety (90) days from shipment, or the remainder of the initial warranty period, whichever is longer.</p>
            </div>
            <div class="t-small-label">2.5 Freight charges: </div>
            <div class="t-content">
                <p>Certified Partners are responsible for returning the defective parts to HOLOWITS at their own cost. If Pick-up Service is available in your region, HOLOWITS will incur all shipping and insurance costs to return the defective parts to HOLOWITS. For the regions covered by the Pick-up Service, please contact local HOLOWITS sales and service representative</p>
            </div>
        </div>
        <div class="item">
            <div class="t-label">3 RMA Process: </div>
            <div class="t-content">
                <p>The flow shown in the following figure is for reference only. The specific RMA processing flow is performed according to the HOLOWITS internal specifications.</p>
            </div>
            <img style="width: 100%;" src="@/assets/image/terms/p1.png" alt="">
        </div>
        <div class="item">
            <div class="t-label">4 Exclusions: </div>
            <div class="t-content">
                <p>The RMA Service does not extend to any damages, malfunctions, or non-conformities caused by</p>
                <p>(a) Force majeure, such as fire, flood, earthquake, war, etc.; </p>
                <p>(b) Abnormal physical or electrical stress (power surges, power outages, etc.); abnormal environmental conditions, misuse, negligence, virus infection, or accident; </p>
                <p>(c) Failure to follow installation, operation, or maintenance instructions supplied by HOLOWITS, together with the product or available on the HOLOWITS website; </p>
                <p>(d) Software, parts, or supplies not supplied by HOLOWITS; </p>
                <p>(e) Combination, modification or service by anyone without the authorization of HOLOWITS or its authorized representative;</p>
                <p>(f) Normal wear due to product use including, but not limited to, product cosmetics and display scratches;</p>
                <p>(g) Any solid-state drive (SSD), M.2 and Intelligent Computing Media (SD cards, SataDom, TPM cards, USB) the usage of which has reached its write endurance limit (excluding the SSD in Dorado storage product).</p>
            </div>
        </div>
        <div class="item">
            <div class="t-label">5 Distributors Responsibility: </div>
            <div class="t-content">
                <p>To enable HOLOWITS to provide the best possible support and service, Distributors will be required to:</p>
                <p>(1) Maintain a proper and adequate environment, and use the HOLOWITS product in accordance with the instructions provided by HOLOWITS. </p>
                <p>(2) When submitting a service request, the customer needs to provide the information required to locate the problem, including the serial number of the equipment, equipment location, and description of the fault as well as other information that is required to analyze the problem, such as alarms, logs, performance measurement results, and operation records.</p>
                <p>(3) Fill out the RMA Application Form and Service Request Form when submitting an RMA request, then send them to HOLOWITS via email. HOLOWITS is not responsible for any delay caused by errors such as a lack of information or inaccurate information.</p>
            </div>
        </div>
        <div class="item">
            <div class="t-label">6 Appendix: </div>
            <p><a style="color: #c7000b; border-bottom: 1px solid #c7000b;" href="https://resources.holowits.com/material/HOLOWITS RMA Policy.xlsx">Return Material Authorization (RMA) Request Form.xlsx</a></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'RMAIndex',
data() {
    return {}
}
}
</script>

<style lang="less" scoped>
.banner {
    width: 100%;
    img {
        width: 100%;
    }
}
.list {
    width: 1080px;
    margin: 25px auto;
    .item {
        & + .item {
            margin-top: 25px;
        }
        .t-label {
            font-size: 22px;
            color: #c7000b;
            font-weight: 600;
            line-height: 50px;
        }
        .t-small-label {
            font-size: 16px;
            color: #c7000b;
            font-weight: 600;
            line-height: 40px;
        }
        .t-content {
            background-image: linear-gradient(#fff, #cdcdcd7a);
            margin-top: 3px;
            padding: 8px;
            border: 1px solid gray;
            border-radius: 10px;
            white-space: pre-line;
            p {
                & + p {
                    margin-top: 30px;
                }
                strong {
                    color: #c7000b;
                }
            }
            ul {
                margin-top: 30px;
                padding-left: 30px;
                li {
                    margin-bottom: 10px;
                    // list-style-type: upper-alpha;
                }
            }
        }
    }
}
</style>